import { template as template_8e9891534d2341f2bcb0594599b8d73b } from "@ember/template-compiler";
import OrganizationPlacesOrCreditInfo from './organization-places-or-credit-info';
import SchoolSessionManagement from './school-session-management';
import UserLoggedMenu from './user-logged-menu';
export default template_8e9891534d2341f2bcb0594599b8d73b(`
  <div class="topbar">
    <OrganizationPlacesOrCreditInfo @placesCount={{@placesCount}} />
    <SchoolSessionManagement />
    <UserLoggedMenu class="topbar__user-logged-menu" @onChangeOrganization={{@onChangeOrganization}} />
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
