import { template as template_327ebaf27c524bb887cc710658d8bd5f } from "@ember/template-compiler";
import dayjsFormat from 'ember-dayjs/helpers/dayjs-format';
import IsCertifiable from '../ui/is-certifiable';
function displayCertificabilityDate(hideCertifiableDate, isCertifiable) {
    return !hideCertifiableDate && isCertifiable !== null;
}
export default template_327ebaf27c524bb887cc710658d8bd5f(`
  <IsCertifiable @isCertifiable={{@isCertifiable}} />
  {{#if (displayCertificabilityDate @hideCertifiableDate @isCertifiable)}}
    <span class="cell-certifiable-at">{{dayjsFormat @certifiableAt "DD/MM/YYYY" allow-empty=true}}</span>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
