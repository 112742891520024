import { template as template_04047325f97e45b9bfda6d28ba216e1d } from "@ember/template-compiler";
import { array } from '@ember/helper';
import { LinkTo } from '@ember/routing';
import { t } from 'ember-intl';
export default template_04047325f97e45b9bfda6d28ba216e1d(`
  <div class="panel participant-tabs">
    <nav class="navbar" aria-label={{t "navigation.assessment-individual-results.aria-label"}}>
      <LinkTo
        @route="authenticated.campaigns.participant-assessment.results"
        @models={{array @campaignId @participationId}}
        class="navbar-item"
      >
        {{t "pages.assessment-individual-results.tab.results"}}
      </LinkTo>
      <LinkTo
        @route="authenticated.campaigns.participant-assessment.analysis"
        @models={{array @campaignId @participationId}}
        class="navbar-item"
      >
        {{t "pages.assessment-individual-results.tab.review"}}
      </LinkTo>
    </nav>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
