import { template as template_a19127df159e48879c314634e7f148dd } from "@ember/template-compiler";
import { concat } from '@ember/helper';
export default template_a19127df159e48879c314634e7f148dd(`
  <th
    class="{{if @size (concat 'table__column--' @size)}}{{if @align (concat ' table__column--' @align)}}"
    ...attributes
  >
    {{yield}}
  </th>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
