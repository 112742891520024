import { template as template_44d1fdd8eb7a4de898b4f9f2c83268d3 } from "@ember/template-compiler";
import { t } from 'ember-intl';
import TooltipWithIcon from '../../ui/tooltip-with-icon';
export default template_44d1fdd8eb7a4de898b4f9f2c83268d3(`
  <span class="evolution-header">

    {{t "pages.campaign-results.table.column.evolution"}}

    <TooltipWithIcon
      @iconName="help"
      @content={{@tooltipContent}}
      @ariaHiddenIcon={{true}}
      class="tooltip-with-icon-small"
    />
  </span>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
