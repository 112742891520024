import { template as template_73ebdfb1c7e04ef3b23d5a50ecfb475e } from "@ember/template-compiler";
import PixButton from '@1024pix/pix-ui/components/pix-button';
import PixModal from '@1024pix/pix-ui/components/pix-modal';
import { t } from 'ember-intl';
export default template_73ebdfb1c7e04ef3b23d5a50ecfb475e(`
  <PixModal
    @title="{{t 'pages.team-members.remove-membership-modal.title'}}"
    @showModal={{@isOpen}}
    @onCloseButtonClick={{@onClose}}
  >
    <:content>
      <p class="remove-member-modal">
        {{t
          "pages.team-members.remove-membership-modal.message"
          memberFirstName=@firstName
          memberLastName=@lastName
          htmlSafe=true
        }}
      </p>
    </:content>
    <:footer>
      <div class="remove-member-modal__action-buttons--container">
        <ul class="remove-member-modal__action-buttons--list">
          <li>
            <PixButton @triggerAction={{@onClose}} @variant="tertiary">
              {{t "common.actions.cancel"}}
            </PixButton>
          </li>

          <li>
            <PixButton @triggerAction={{@onSubmit}} @variant="error">
              {{t "pages.team-members.remove-membership-modal.actions.remove"}}
            </PixButton>
          </li>
        </ul>
      </div>
    </:footer>
  </PixModal>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
