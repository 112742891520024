import { template as template_eba7e30bfc0140d68875348de28a40c9 } from "@ember/template-compiler";
import { LinkTo } from '@ember/routing';
export default template_eba7e30bfc0140d68875348de28a40c9(`
  <li class="dropdown__item dropdown__item--link">
    <LinkTo @route={{@linkTo}} class="link" ...attributes>
      {{yield}}
    </LinkTo>
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
