import { template as template_1d7e1a97f2564ef4828253262fae83cd } from "@ember/template-compiler";
import { t } from 'ember-intl';
import { gt } from 'ember-truth-helpers';
export default template_1d7e1a97f2564ef4828253262fae83cd(`
  <div class="table__column--center">
    {{#if (gt @tutorials.length 0)}}
      {{t "pages.campaign-review.table.analysis.column.tutorial-count.value" count=@tutorials.length}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
