import { template as template_53920796b4494b10a0f64771008de088 } from "@ember/template-compiler";
export default template_53920796b4494b10a0f64771008de088(`
  <section class="import-card">
    <h2 class="import-card__title">{{@cardTitle}}</h2>
    <div class="import-card__details">
      {{yield to="cardDetails"}}
    </div>

    <div class="import-card__footer">
      {{yield to="cardFooter"}}
    </div>
  </section>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
