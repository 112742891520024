import { template as template_5472928d7b1347edb6935ed4f534c4c4 } from "@ember/template-compiler";
import Certification from './certification';
import LanguageAvailability from './language-availability';
import Scommunication from './sco-communication';
import Survey from './survey';
export default template_5472928d7b1347edb6935ed4f534c4c4(`
  <div class="top-banners">
    <Certification />
    <Scommunication />
    <LanguageAvailability />
    <Survey />
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
